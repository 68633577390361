export type GrantStatus = 'pending' | 'rejected' | 'approved' | 'contacted';

export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const PENDING = 'pending';
export const CONTACTED = 'contacted';

export type ApiGrantChange = {
  status: string;
  rejectionReason: string;
  rejectionReasonIndex: number;
  comment: string;
};
