











import Vue from 'vue';
export default Vue.extend({
  name: 'admin-section',
  props: {
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'white',
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
});
