












































import {
  ApiGrantEventDto,
  ApiGrantWithApiAndHistoryDto,
} from '@portals/shared/admin/ApiGrantDto';
import { format } from 'date-fns';
import Vue from 'vue';

export default Vue.extend({
  name: 'api-grant-history',
  components: {},
  filters: {
    formatDate(timestamp: Date) {
      return format(new Date(timestamp), 'DD.MM.YYYY HH:mm');
    },
    mapAction(action: string) {
      switch (action) {
        case 'update_status':
          return 'changed status to';
        case 'comment':
          return 'commented';
        case 'create':
          return 'created grant';
        default:
          return action;
      }
    },
  },
  props: {
    grant: {
      type: Object as () => ApiGrantWithApiAndHistoryDto,
      default: undefined,
    },
  },
  methods: {
    formatMessage(message: string) {
      const messages = message.split('\n');
      return messages;
    },
    useCase(event: ApiGrantEventDto) {
      return event.apiGrantSnapshot?.useCase ?? event.appSnapshot?.useCase;
    },
  },
});
