






































































































import { ApiGrantWithApiAndHistoryDto } from '@portals/shared/admin/ApiGrantDto';
import Vue from 'vue';

import DnbButton from '@/components/DnbButton.vue';
import DnbTextarea from '@/components/DnbTextarea.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import ApiGrantHistory from '@/views/ApiGrant/ApiGrantHistory.vue';

import {
  ApiGrantChange,
  APPROVED,
  CONTACTED,
  REJECTED,
} from '../../models/ApiGrant';
import { REJECTION_RESPONSES } from './ApiGrantRejectionResponses';

type Data = {
  grantIndex: number;
  rejectionResponses: string[];
  validationError: string;
  rejectionReasonsSelected: boolean[];
};
export default Vue.extend({
  name: 'api-grant-review-dialog',
  components: {
    DnbButton,
    DnbTextarea,
    ApiGrantHistory,
    DnbWarning,
  },
  model: {
    prop: 'grant-changes',
    event: 'update',
  },
  props: {
    grants: {
      type: Array as () => ApiGrantWithApiAndHistoryDto[],
      default: [],
    },
    grantChanges: {
      type: Object,
      default: () => ({}),
    },
  },
  data(): Data {
    return {
      grantIndex: 0,
      rejectionResponses: REJECTION_RESPONSES,
      validationError: '',
      rejectionReasonsSelected: new Array(REJECTION_RESPONSES.length).fill(
        false,
      ),
    };
  },
  computed: {
    currentGrant(): ApiGrantWithApiAndHistoryDto {
      return this.grants[this.grantIndex];
    },
    hasMoreGrants(): boolean {
      return this.grantIndex < this.grants.length - 1;
    },

    currentGrantChanges(): ApiGrantChange {
      const defaults = {
        status: '',
        message: '',
        rejectionResponseIndex: -1,
        comment: '',
      };
      return { ...defaults, ...this.grantChanges[this.currentGrant.id] };
    },
  },
  methods: {
    commitChange(change: {
      status?: string;
      comment?: string;
      message?: string;
      rejectionResponseIndex?: number;
    }) {
      this.validationError = '';
      const grantId = this.currentGrant.id;
      const currentGrantChanges = { ...this.grantChanges[grantId] } || {};

      if (change.status && change.status === APPROVED) {
        delete currentGrantChanges.message;
        delete currentGrantChanges.rejectionResponseIndex;
      }

      this.$emit('update', {
        ...this.grantChanges,
        [grantId]: { ...currentGrantChanges, ...change },
      });
    },
    onCommentChange(comment: string) {
      this.commitChange({ comment });
    },
    onRejectionReasonClick(index: number) {
      this.rejectionReasonsSelected[index] =
        !this.rejectionReasonsSelected[index];

      this.commitChange({
        message: REJECTION_RESPONSES.filter(
          (reason, index) => this.rejectionReasonsSelected[index],
        )
          .map((reason) => `\n- ${reason}`)
          .join(''),
      });
    },
    onGrantClick(index: number) {
      this.grantIndex = index;
      this.validationError = '';
    },
    closeAndDiscard() {
      this.$emit('close');
      const changes = { ...this.grantChanges };
      for (const grant of this.grants) {
        delete changes[grant.id];
      }
      this.$emit('update', changes);
    },
    isValidChange() {
      const currentChanges = this.grantChanges[this.currentGrant.id];
      if (!currentChanges) {
        this.validationError =
          'You have not made any changes. Click discard to go back.';
        return false;
      }

      const isApproved = currentChanges.status === APPROVED;
      const isRejected = currentChanges.status === REJECTED;
      const isContacted = currentChanges.status === CONTACTED;
      const hasReason = !!currentChanges.message;
      if (isApproved || isContacted) {
        return true;
      } else if (isRejected) {
        if (!hasReason) {
          this.validationError =
            'Please select a reson for rejecting this grant';
        }
        return hasReason;
      }
      return true;
    },
    reviewNext() {
      if (this.isValidChange()) {
        this.grantIndex++;
      }
    },
    close() {
      if (this.isValidChange()) {
        this.$emit('close');
      }
    },
    grantStatus(grant: ApiGrantWithApiAndHistoryDto, _index: number) {
      if (this.grantChanges[grant.id] && this.grantChanges[grant.id].status) {
        return this.grantChanges[grant.id].status;
      }
      return grant.status;
    },

    isReviewing(index: number): boolean {
      return this.grantIndex === index;
    },
    isEdited(grant: ApiGrantWithApiAndHistoryDto) {
      return !!this.grantChanges[grant.id];
    },
    isChangedTo(status: string): boolean {
      const grantId = this.currentGrant.id;
      const changes = this.grantChanges[grantId];
      if (changes && changes.status) {
        return changes.status === status;
      }
      return false;
    },
  },
});
