

























import { ApiGrantWithApiAndHistoryDto } from '@portals/shared/admin/ApiGrantDto';
import Vue from 'vue';

import DnbButton from '@/components/DnbButton.vue';
import DnbIcon from '@/components/DnbIcon.vue';

export default Vue.extend({
  name: 'api-grant-list-item',
  components: {
    DnbButton,
    DnbIcon,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    grant: {
      type: Object as () => ApiGrantWithApiAndHistoryDto,
      default: undefined,
    },
    buttonText: {
      type: String,
      default: undefined,
    },
    edited: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      required: true,
    },
  },
});
