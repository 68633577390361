







































































































































































































import {
  ApiGrantWithApiAndHistoryDto,
  AppWithApiGrantsAndOwnerDto,
} from '@portals/shared/admin/ApiGrantDto';
import { TeamDto } from '@portals/shared/admin/TeamDto';
import { UserDto } from '@portals/shared/admin/UserDto';
import { format } from 'date-fns';
import Vue from 'vue';

import AdminSection from '@/components/AdminSection.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbButton from '@/components/DnbButton.vue';
import Loader from '@/components/Loader.vue';
import { ApiGrantChange } from '@/models/ApiGrant';
import { fetchApiGrant, updateGrant } from '@/service/apiGrantService';
import ApiGrantListItem from '@/views/ApiGrant/ApiGrantListItem.vue';
import ApiGrantReviewDialog from '@/views/ApiGrant/ApiGrantReviewDialog.vue';

import { Breadcrumb } from '../../models/Breadcrumb';

type Data = {
  appId: string | undefined;
  app: AppWithApiGrantsAndOwnerDto | undefined;
  grantChanges: { [grantId: string]: ApiGrantChange };
  selectedGrants: ApiGrantWithApiAndHistoryDto[] | undefined;
  errorMessage: string;
  loading: boolean;
};

export default Vue.extend({
  name: 'api-grant',
  components: {
    DnbButton,
    AdminSection,
    ApiGrantReviewDialog,
    ApiGrantListItem,
    Loader,
    Breadcrumbs,
  },
  filters: {
    formatDate(date: Date) {
      return format(date, 'DD.MM.YYYY');
    },
  },
  data(): Data {
    return {
      app: undefined,
      grantChanges: {},
      selectedGrants: undefined,
      errorMessage: '',
      appId: undefined,
      loading: false,
    };
  },
  computed: {
    hasChanges(): boolean {
      return Object.entries(this.grantChanges).length > 0;
    },
    grantedBy(): string {
      // Todo UX: Find a nice way to dispalay grantedBy for multiple APIs for team apps.
      const latestGrantEvent = this.app?.grants[0].history.find(
        (event) => event.action === 'create' || event.action === 'resubmit',
      );
      return latestGrantEvent ? latestGrantEvent.performedBy : 'N/A';
    },
    grantedByEmail(): string {
      const latestGrantEvent = this.app?.grants[0].history.find(
        (event) => event.action === 'create' || event.action === 'resubmit',
      );
      return latestGrantEvent ? latestGrantEvent.performedByEmail : 'N/A';
    },
    user(): UserDto | undefined | null {
      return this.app?.user;
    },
    team(): TeamDto | undefined | null {
      return this.app?.team;
    },
    breadcrumbs(): Breadcrumb[] {
      const crumbs: Breadcrumb[] = [
        {
          name: 'API Grants',
          path: '/api-grants',
        },
        {
          name: this.app ? this.app.name : '',
        },
      ];

      return crumbs;
    },
  },

  mounted() {
    this.appId = this.$route.params.appId;
    this.getApiGrant();
  },

  methods: {
    async getApiGrant() {
      this.app = await fetchApiGrant(this.$route.params.appId);
    },
    async updateApiGrant() {
      this.loading = true;
      const response = await updateGrant(
        this.$route.params.appId,
        this.grantChanges,
      );
      if (this.app) {
        const grants = response;
        this.app = { ...this.app, grants };
      }
      this.loading = false;
      this.grantChanges = {};
    },
    onCloseReviewGrant() {
      this.selectedGrants = undefined;
    },
    cancelAction() {
      this.$router.go(-1);
    },
    grantsByStatus(statusFilter: string): ApiGrantWithApiAndHistoryDto[] {
      if (this.app && this.app.grants) {
        return this.app.grants.filter((grant: ApiGrantWithApiAndHistoryDto) => {
          const newStatus = this.grantChanges[grant.id]
            ? this.grantChanges[grant.id].status
            : '';

          if (newStatus) {
            return newStatus === statusFilter;
          }
          return grant.status === statusFilter;
        });
      }

      return [];
    },
    isEdited(grant: ApiGrantWithApiAndHistoryDto) {
      return !!this.grantChanges[grant.id];
    },
  },
});
