










import Vue from 'vue';

export default Vue.extend({
  name: 'loader',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Loading...',
    },
  },
});
